import React, { useState, useEffect, useRef } from "react";

import config from "../../../config";
import validator from "../../../lib/validation";
import axiosCall from "../../../lib/axios";
import Modal from "../../modal/Modal";

const NewCommunity = ({ language, signout }) => {
  //////////// INITIALIZE ///////////
  const [newCommunity, setNewCommunity] = useState(false);
  const [errUname, setErrUname] = useState("");
  const [errQty, setErrQty] = useState("");
  const [formError, setFormError] = useState(null);
  const [isPrivate, setIsPrivate] = useState(true);
  const [qty, setQty] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState({});

  const uname = useRef();
  const license_type = useRef();
  const license_qty = useRef();
  const private_community = useRef();
  const remittance = useRef();
  const verified = useRef();
  const ex_knowledge = useRef();
  const success_key = useRef();
  const success_id = useRef();

  const flexStyle = {
    marginTop: "1.5em",
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
  };

  useEffect(() => {
    if (showModal) messageStatus(successMessage);
  }, [showModal]);

  //////////// FUNCTIONS ///////////

  const messageStatus = async (data) => {
    success_key.current.innerText = data?.license_key;
    success_id.current.innerText = data?._id;
  };

  const checkQty = (e) => {
    //check license quantity
    if (e.value) {
      let _int = parseInt(e.value);
      setQty(Math.floor(_int));
    } else {
      setQty("");
    }
  };

  const clearErrors = (reset = false) => {
    setErrUname("");
    setErrQty("");
    setFormError(null);
    if (reset) setQty("");
  };

  const submitData = async () => {
    // init
    clearErrors();
    let data = {};

    //check uname
    let _uname = uname.current.value.toLowerCase().trim();
    var uname_chk = validator.usernameValidator(_uname);
    if (!uname_chk) {
      setErrUname(language.labels.admin.errors.invalid_uname);
      setFormError("uname");
      uname.current.focus();
      return;
    } else {
      data.uname = _uname;
    }

    //check private and license quantity
    if (isPrivate) {
      data.private = isPrivate;
      if (qty < 1) {
        setErrQty(language.labels.admin.errors.license_qty);
        setFormError("license_qty");
        license_qty.current.focus();
        return;
      } else {
        data.license_qty = qty;
      }
    }

    //remaining fields
    data.license_type = license_type.current.value;
    if (remittance.current.checked) data.remittance = true;
    if (verified.current.checked) data.verified = true;
    if (ex_knowledge.current.checked) data.ex_knowledge = true;

    //call api
    let result = await axiosCall("admin/community/new", data);
    if (result.success) {
      if (result.status === 200) {
        setSuccessMessage(result.data);
        setShowModal(true);
      } else {
        setErrUname(language.labels.admin.errors.uname_taken);
        setFormError("uname");
        uname.current.focus();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      submitData();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const onCancel = (modal = false) => {
    clearErrors(true);
    setNewCommunity(false);
    if (modal) {
      setShowModal(false);
      success_key.current = "";
      success_id.current = "";
    }
  };

  //////////// GUI ///////////
  return (
    <>
      <div
        className="card-free"
        style={{
          paddingTop: "1.5em",
          paddingBottom: "1.5em",
          marginTop: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5em",
            alignItems: "center",
          }}
        >
          <button
            className="button-glyph"
            onClick={() => setNewCommunity(true)}
          >
            +
          </button>
          <div>
            <span className="link" onClick={() => setNewCommunity(true)}>
              {language.labels.admin.communities.new}
            </span>
          </div>
        </div>
        {newCommunity && (
          <div style={{ padding: "1em 0" }}>
            {/* unique name */}
            <div style={flexStyle}>
              <div>{language.labels.communities.headings.uname}</div>
              <input
                ref={uname}
                type="text"
                className={formError === "uname" && "error"}
                maxLength={config.string.title}
              />
            </div>
            {errUname && (
              <div className="errtext" style={{ margin: "0.5em 0 0 0" }}>
                {errUname}
              </div>
            )}

            <div style={flexStyle}>
              <div>{language.labels.communities.headings.license_type}</div>
              <select ref={license_type}>
                {["premium", "public", "expert"].map((license, index) => {
                  return (
                    <option value={license}>
                      {language.labels.admin.communities.license_types[license]}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={flexStyle}>
              <div>{language.labels.communities.headings.license_qty}</div>
              <input
                ref={license_qty}
                type="number"
                value={qty}
                className={formError === "license_qty" && "error"}
                onChange={(e) => checkQty(e.target)}
              />
            </div>
            {errQty && (
              <div className="errtext" style={{ margin: "0.5em 0 0 0" }}>
                {errQty}
              </div>
            )}

            <div style={flexStyle}>
              <input
                ref={private_community}
                id="private"
                type="checkbox"
                checked={isPrivate}
                onChange={() => setIsPrivate(!isPrivate)}
              />
              <label htmlFor="private">
                {language.labels.communities.headings.private}
              </label>
            </div>

            <div style={flexStyle}>
              <input ref={remittance} id="remittance" type="checkbox" />
              <label htmlFor="remittance">
                {language.labels.communities.headings.remittance}
              </label>
            </div>

            <div style={flexStyle}>
              <input ref={verified} id="verified" type="checkbox" />
              <label htmlFor="verified">
                {language.labels.communities.headings.verified}
              </label>
            </div>

            <div style={flexStyle}>
              <input ref={ex_knowledge} id="ex_knowledge" type="checkbox" />
              <label htmlFor="ex_knowledge">
                {language.labels.communities.headings.ex_knowledge}
              </label>
            </div>

            {/* buttons */}
            <div style={{ display: "flex", gap: "0.25em", marginTop: "3em" }}>
              <button className="button-secondary" onClick={onCancel}>
                {language.labels.app.cancel}
              </button>
              <button className="button" onClick={submitData}>
                {language.labels.app.apply}
              </button>
            </div>
          </div>
        )}
      </div>

      {/* success modal */}
      <Modal
        className="modal-dynamic"
        show={showModal}
        title={language.labels.app.success}
        language={language}
      >
        <div style={{ display: "flex", marginTop: "1em", gap: "1em" }}>
          <div>{language.labels.communities.headings.license_key}</div>
          <div style={{ fontWeight: 500 }} ref={success_key}></div>
        </div>

        <div style={{ display: "flex", marginTop: "1.5em", gap: "1em" }}>
          <div>{language.labels.communities.headings.id}</div>
          <div style={{ fontWeight: 500 }} ref={success_id}></div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "2.5em",
            gap: "0.25em",
            paddingBottom: "0.5em",
          }}
        >
          <button className="button-secondary" onClick={() => onCancel(true)}>
            {language.labels.app.ok}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default NewCommunity;
