import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";

const Welcome = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZE ////////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  //////////// USER INTERACTIONS ///////////
  const contact_sales = (e) => {
    e.preventDefault();
    navigate("/contact");
  };

  //////////// RENDER GUI ////////////
  return (
    <div className="main" ref={pageRefs._main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
      ></div>
      <div
        ref={pageRefs.skip_link}
        className="link sr-only"
        role="link"
        onClick={() => pageFunctions.skipLink(false)}
        onKeyUpCapture={(e) =>
          e.key === "Enter" && pageRefs.main_focus.current.focus()
        }
        onFocus={() => pageFunctions.skipLink()}
        onBlur={() => pageFunctions.skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>
      <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
      <SideBar
        language={language}
        signout={signout}
        auth={auth}
        communities={communities.length}
        community={community}
        resetMenu={pageFunctions.resetMenu}
        ref={pageRefs.sidebar_ref}
      />
      <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
        <div
          className="page-section"
          style={{
            paddingBottom: "3em",
            maxWidth: "44rem",
          }}
        >
          <h1 className="heading">{language.labels.app.welcome_hub}</h1>

          <div style={{ margin: "1em 0 2em 0" }}>
            {language.labels.brand.about_hub}
          </div>

          <div
            style={{ marginTop: "3em", fontSize: "1.1em", fontWeight: "500" }}
          >
            {language.labels.communities.license.got_key}
          </div>

          <div style={{ marginTop: "0.5em" }}>
            {language.labels.communities.license.use_key}
          </div>

          <div style={{ margin: "1em 0" }}>
            <button
              id="new_link"
              className="button"
              style={{ fontSize: "1.1em" }}
              onClick={() => {
                navigate("/newcommunity");
              }}
            >
              {language.labels.navigation.community_start}
            </button>
          </div>

          <div style={{ display: "flex", gap: "0.5em", marginBottom: "2em" }}>
            <div>{language.labels.communities.license.no_key}</div>
            <button className="link" onClick={(e) => contact_sales(e)}>
              {language.website.contact_sales}
            </button>
          </div>

          <div
            style={{ marginTop: "3em", fontSize: "1.1em", fontWeight: "500" }}
          >
            {language.labels.brand.not_expert}
          </div>

          <div style={{ margin: "0.5em 0" }}>
            <div>{language.labels.brand.about_app}</div>
            <button
              className="button"
              style={{ marginTop: "1em", fontSize: "1.1em" }}
              onClick={() => navigate("/gotoapp")}
            >
              {language.labels.app.launch_go}
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Welcome;
