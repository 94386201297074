import React, { useState } from "react";
import CsvDownloader from "react-csv-downloader";

import axiosCall from "../../../lib/axios";
import Pdf from "../../../media/pdf.svg";

const FindCommunity = ({ language, signout }) => {
  //////////// INITIALIZE ///////////
  let searchTimer;

  const communityHeader = [
    { id: "_id", displayName: language.labels.communities.headings.id },
    {
      id: "uname",
      displayName: language.labels.communities.headings.uname,
    },
    {
      id: "license_key",
      displayName: language.labels.communities.headings.license_key,
    },
    {
      id: "license_activated",
      displayName: language.labels.communities.headings.license_activated,
    },
    {
      id: "license_type",
      displayName: language.labels.communities.headings.license_type,
    },
    {
      id: "license_qty",
      displayName: language.labels.communities.headings.license_qty,
    },
    {
      id: "private",
      displayName: language.labels.communities.headings.private,
    },
    {
      id: "remittance",
      displayName: language.labels.communities.headings.remittance,
    },
    {
      id: "active",
      displayName: language.labels.communities.headings.active,
    },
    {
      id: "draft",
      displayName: language.labels.communities.headings.draft,
    },
    {
      id: "verified",
      displayName: language.labels.communities.headings.verified,
    },
    {
      id: "ex_video",
      displayName: language.labels.communities.headings.ex_video,
    },
    {
      id: "ex_knowledge",
      displayName: language.labels.communities.headings.ex_knowledge,
    },
    {
      id: "stream_license",
      displayName: language.labels.communities.headings.id,
    },
    {
      id: "created",
      displayName: language.labels.communities.headings.created,
    },
    {
      id: "owner_id",
      displayName: language.labels.communities.headings.owner_id,
    },
  ];

  //////////// SEARCH FUNCTIONS ///////////
  const search = (searchTerm) => {
    console.log(searchTerm);
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      if (searchTerm.trim() !== "") findCommunities(searchTerm);
    }, 500);
  };

  const checkSearch = (term) => {
    if (term === "") {
      clearTimeout(searchTimer);
      console.log("k");
    } else {
      search(term);
    }
  };

  const findCommunities = async (searchTerm) => {
    //call api
    let result = await axiosCall(
      "admin/community/find",
      { query: searchTerm.toLowerCase() },
      false
    );

    if (result.success) {
      console.log(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      findCommunities(searchTerm);
    } else {
      console.log(result);
      //refresh token expired or unknown error
      // signout();
    }
  };

  //////////// DOWNLOAD DATA ///////////
  const communityReport = async (attempt = 1) => {
    try {
      let result = await axiosCall("admin/communities");
      if (result.success) {
        return result.data;
      } else if (result.refresh) {
        throw new Error();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } catch (err) {
      if (attempt < 2) {
        return communityReport(attempt + 1);
      } else {
        //unknown error
        signout();
      }
    }
  };

  return (
    <div
      className="card-free"
      style={{ paddingTop: "1.5em", paddingBottom: "1.5em" }}
    >
      {/* Community Search */}
      <div className="text-label" style={{ marginBottom: "0.25em" }}>
        {language.labels.admin.communities.lookup}
      </div>
      <div className="web-search" style={{}}>
        <div className="glyphs">f</div>
        <input
          type="search"
          onChange={(e) => checkSearch(e.target.value)}
          placeholder={language.labels.admin.communities.find}
          size={20}
        />
      </div>

      {/* Communities Download */}
      <CsvDownloader
        style={{ display: "inline" }}
        className="link"
        filename="community list"
        columns={communityHeader}
        datas={communityReport}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5em",
            marginTop: "2em",
            alignItems: "center",
          }}
        >
          <img src={Pdf} style={{ width: "24px" }} />
          <div style={{ fontSize: "0.9em" }}>
            {language.labels.admin.communities.download}
          </div>
        </div>
      </CsvDownloader>
    </div>
  );
};
export default FindCommunity;
