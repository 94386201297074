import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const Hub = ({ signout }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const cookies = new Cookies();

  useEffect(() => {
    let invitation = sessionStorage.getItem("invitation");
    let redirection = cookies.get("redirection");
    let communities = JSON.parse(localStorage.getItem("communities"));

    if (!auth.uid) {
      //not authorized
      signout(false);
    } else if (auth.agree_terms !== "ok") {
      //go to terms
      navigate("/terms");
    } else if (invitation) {
      //go to invitation
      navigate("/invitation");
    } else if (redirection) {
      //redirect
      cookies.remove("redirection");
      if (redirection.page) {
        //get state
        if (redirection.state) {
          navigate("/" + redirection.page, { state: redirection.state });
        } else {
          navigate("/" + redirection.page);
        }
      }
    } else {
      if (auth.community_id && auth.role) {
        navigate("/mycommunity");
      } else if (communities.length > 1) {
        navigate("/communityhub");
      } else {
        navigate("/welcome");
      }
    }
  }, []);

  return <></>;
};

export default Hub;
