import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../../config";
import React, { useRef, useEffect, useState } from "react";

/**
 * The public navigation bar to be displayed on public routes
 * @param {Object} props
 * @param {Object} props.language - language object used for displaying labels
 * @param {Boolean} props.buttons - whether or not render the signin button/avatar on the right
 * @param {Boolean} props.join - if buttons is true show join button instead of signin button/avatar
 * @param {Boolean} props.clear - if the navbar should be transluscent when scrolled to top of screen (default: false)
 * @param {Boolean} props.white - if the navbar's font color should be white. If clear is set to true, this will be set true as well. It can be overwritten (default: false)
 * @returns
 */
const NavBar = ({
  language,
  buttons,
  join,
  clear = false,
  white = false || clear,
}) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  const [showHeader, setShowHeader] = useState(true);
  const [clearHeader, setClearHeader] = useState(clear);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const lastScrollY = useRef(0);
  const topbarRef = useRef();
  const topBarHeight = useRef(0);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 680px)");

    // Listener for media query changes
    const handleWidthChange = (e) => {
      if (e.matches) {
        setShowMobileMenu(false);
      }
    };

    // Add listener
    mediaQuery.addEventListener("change", handleWidthChange);

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // console.log(currentScrollY);

      // Check if scrolling down or up
      if (currentScrollY > lastScrollY.current && currentScrollY > 80) {
        // Scrolling down
        // setShowHeader(false);
      } else {
        // Scrolling up
        setShowHeader(true);
      }
      if (currentScrollY <= 80 && clear) {
        setClearHeader(true);
      } else {
        setClearHeader(false);
      }

      lastScrollY.current = currentScrollY;
    };
    //listen to scroll
    if (topbarRef.current) {
      topBarHeight.current = topbarRef.current.getBoundingClientRect().height; // Get element height
    }

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      mediaQuery.removeEventListener("change", handleWidthChange);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //navigation
  const goTo = (page) => {
    if (window.location.pathname === `/${page}`) {
      setShowMobileMenu(false);
      window.scrollTo(0, 0);
    } else {
      navigate(`/${page}`);
    }
  };

  const renderNavButtons = ["platform", "solutions", "pricing"].map(
    (page, index) => (
      <div
        key={index}
        className="public-nav-button flex-680px hover"
        style={{ position: "relative" }}
      >
        <div
          role="link"
          onClick={() => goTo(page)}
          onKeyUpCapture={(e) => e.key === "Enter" && goTo(page)}
          tabIndex={0}
        >
          {language.website.pub_bar[page]}
        </div>
        <div
          className={`public-nav-underline ${
            clearHeader && white ? "clear" : ""
          }`}
        ></div>
      </div>
    )
  );

  const renderMobileNavButtons = ["platform", "solutions", "pricing"].map(
    (page, index) => (
      <div
        key={index}
        className={"public-nav-button mobile hover"}
        style={{ position: "relative" }}
      >
        <div
          role="link"
          onClick={() => goTo(page)}
          onKeyUpCapture={(e) => e.key === "Enter" && goTo(page)}
          tabIndex={0}
        >
          {language.website.pub_bar[page]}
        </div>
        <div className="public-nav-underline mobile hover"></div>
      </div>
    )
  );

  return (
    <>
      <div
        ref={topbarRef}
        className={`public-nav-container ${clearHeader ? "clear" : ""}`}
        style={{
          color: white && clearHeader && "white",
          transform:
            showHeader || showMobileMenu
              ? "translateY(0)"
              : "translateY(-100%)",
        }}
      >
        <div className="public-nav">
          <div
            aria-hidden="true"
            className={`public-nav-icon glyphs hover ${
              clearHeader && white ? "clear" : ""
            }`}
            // style={{ fontSize: "2.7em" }}
            onClick={() => goTo("home")}
          >
            Y
          </div>
          <div
            style={{
              paddingBottom: "0.15em",
              display: "flex",
            }}
          >
            <span
              role="link"
              className={`public-nav-logo font-logo hover ${
                clearHeader && white ? "clear" : ""
              }`}
              onClick={() => goTo("home")}
              onKeyUpCapture={(e) => e.key === "Enter" && goTo("home")}
              tabIndex={0}
            >
              {language.labels.brand.name}
            </span>
          </div>
          <div style={{ flexBasis: "1em" }}></div>
          <div className={"public-nav-buttons"}>{renderNavButtons}</div>
          {buttons &&
            (join ? (
              <button
                className={`public-nav-signin flex-680px ${
                  clearHeader && white ? "clear" : ""
                }`}
                onClick={() => goTo("signup")}
              >
                <div>{language.labels.authentication.signup.join}</div>
                <span className="glyphs">{">"}</span>
              </button>
            ) : auth.account_type &&
              auth.account_type !== "suspended" &&
              auth.agree_terms === "ok" ? (
              <button
                className={`public-nav-signin flex-680px ${
                  clearHeader && white ? "clear" : ""
                }`}
                onClick={() => goTo("account")}
              >
                <div>{language.labels.app.hub_go}</div>
                <span className="glyphs">{">"}</span>
              </button>
            ) : (
              <button
                className={`public-nav-signin flex-680px ${
                  clearHeader && white ? "clear" : ""
                }`}
                onClick={() => goTo("login")}
              >
                <div>{language.labels.authentication.signin.user}</div>
                <span className="glyphs">{">"}</span>
              </button>
            ))}
          <button
            className={`public-nav-hamburger glyphs hidden-680px  ${
              clearHeader && white ? "clear" : ""
            }`}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            H
          </button>
        </div>
      </div>
      <div className={`public-nav-mobile ${showMobileMenu ? "" : "hidden"}`}>
        <div
          ref={topbarRef}
          className="public-nav-container"
          style={{
            transform:
              showHeader || showMobileMenu
                ? "translateY(0)"
                : "translateY(-100%)",
          }}
        >
          <div className="public-nav">
            <div
              aria-hidden="true"
              className="public-nav-icon glyphs hover"
              // style={{ fontSize: "2.7em" }}
              onClick={() => goTo("home")}
            >
              Y
            </div>
            <div
              style={{
                paddingBottom: "0.15em",
                display: "flex",
              }}
            >
              <span
                role="link"
                className="public-nav-logo font-logo hover"
                onClick={() => goTo("home")}
                onKeyUpCapture={(e) => e.key === "Enter" && goTo("home")}
                tabIndex={0}
              >
                {language.labels.brand.name}
              </span>
            </div>
            <div style={{ flex: 1 }}></div>
            <button
              className="public-nav-hamburger glyphs"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              x
            </button>
          </div>
        </div>
        <div className={"public-nav-buttons mobile"}>
          {renderMobileNavButtons}
          {buttons &&
            (join ? (
              <button
                className="public-nav-signin mobile"
                onClick={() => goTo("signup")}
              >
                <div>{language.labels.authentication.signup.join}</div>
                <span className="glyphs">{">"}</span>
              </button>
            ) : auth.account_type &&
              auth.account_type !== "suspended" &&
              auth.agree_terms === "ok" ? (
              <button
                className="public-nav-signin mobile"
                onClick={() => goTo("account")}
              >
                <div>{language.labels.app.hub_go}</div>
                <span className="glyphs">{">"}</span>
              </button>
            ) : (
              <button
                className="public-nav-signin mobile"
                onClick={() => goTo("login")}
              >
                <div>{language.labels.authentication.signin.user}</div>
                <span className="glyphs">{">"}</span>
              </button>
            ))}
        </div>
      </div>
    </>
  );
};

export default NavBar;
