import { React, useState, useEffect, useRef } from "react";
import config from "../../../../config";
import axiosCall from "../../../../lib/axios";

const Key = ({
  language,
  animation,
  next,
  navigate,
  setNewCommunity,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const [errMsg, setErrMsg] = useState("");
  const key_input = useRef();

  useEffect(() => {
    if (animation && animation.step === 0) {
      if (animation.direction === 1) {
        document.getElementById("Key").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("Key").style.animationName = "slideout";
        document.getElementById("Key").style.animationDirection = "reverse";
      }
    }
  }, []);

  //////////// USER INTERACTIONS ///////////
  const contact_sales = (e) => {
    e.preventDefault();
    navigate("/contact");
  };

  const nextStep = async (e) => {
    e.preventDefault();

    //reset error message
    setErrMsg("");

    //check licenseKey
    let licenseKey = key_input.current.value;
    let license_key = licenseKey.toLowerCase().trim();
    if (!license_key) {
      setErrMsg(language.labels.error.key_invalid);
      key_input.current.focus();
      return;
    }

    // validate key
    let result = await axiosCall("community/validate", { license_key });
    if (result.success) {
      setErrMsg(language.labels.error.key_invalid);
      key_input.current.focus();

      if (result.status === 200) {
        //proceed to next step
        setNewCommunity(result.data);
        document.getElementById("Key").style.animationName = "slideout";
        next({ step: 1, direction: 1 });
      } else {
        let msg = language.labels.error.key_invalid;
        if (result.data?.message === "ACTIVATED") {
          setErrMsg(
            language.labels.error.key_activated.replace(
              /{sales}/g,
              config.email.sales
            )
          );
        } else {
          setErrMsg(language.labels.error.key_invalid);
          key_input.current.focus();
        }
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      nextStep(e);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// RENDER GUI //////////////////
  return (
    <div className="mobile-margin" style={{ position: "relative" }}>
      <form
        className="block-step page-section mobile-margin"
        id="Key"
        onSubmit={(e) => nextStep(e)}
        aria-label={language.labels.aria.form.new_community}
        style={{ maxWidth: "44rem" }}
      >
        <div>{language.labels.communities.license.about}</div>

        <input
          ref={key_input}
          className={errMsg && "error"}
          placeholder={language.labels.communities.license.enter_key}
          aria-placeholder={language.labels.communities.license.enter_key}
          style={{
            width: "100%",
            maxWidth: "44rem",
            display: "block",
            marginTop: "1em",
          }}
          maxLength={config.string.title}
        />
        {errMsg && (
          <div className="errtext" role="alert">
            {errMsg}
          </div>
        )}

        <div style={{ display: "flex", margin: "2em 0" }}>
          <button className="button" type="submit">
            {language.labels.app.action_next}
          </button>
        </div>

        <div style={{ display: "flex", gap: "0.5em" }}>
          <div>{language.labels.communities.license.no_key}</div>
          <button className="link" onClick={(e) => contact_sales(e)}>
            {language.website.contact_sales}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Key;
